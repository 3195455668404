import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { isEmpty } from "../../utils/common";
import ChooseLocation from "../ChooseLocation";

const LocationInput = ({
  defaultInput,
  placeholder,
  icon,
  className,
  inputClassName,
  onChangePlace,
}) => {
  const [text, setText] = useState("");
  const [showChooseModal, setShowChooseModal] = useState(false);

  useEffect(() => {
    setText(defaultInput);
  }, [defaultInput]);

  return (
    <div
      className={`flex justify-start items-center mt-4 p-3 w-full min-h-[42px] rounded bg-[#f5f5f5] ${className}`}
    >
      <div className="w-[29px]">
        {icon ?? <IoSearch size={20} color={"#333"} />}
      </div>
      <div className="flex-1" onClick={() => setShowChooseModal(true)}>
        <p
          className={`flex-1 text-14  font-light outline-none ${
            isEmpty(text) ? "text-gray6" : "text-black"
          } ${inputClassName}`}
        >
          {isEmpty(text) ? placeholder : text}
        </p>
      </div>

      {/* <input 
        value={text}
        placeholder={placeholder}
        className={`flex-1 text-14 text-black outline-none ${inputClassName}`}
        // onFocus={() => setShowChooseModal(true)}
        onClick={() => setShowChooseModal(true)}
      /> */}
      <ChooseLocation
        showModal={showChooseModal}
        onClose={(data) => {
          if (data != null) {
            onChangePlace(data);
            setText(data.formatted_address);
            setShowChooseModal(false);
          } else {
            setShowChooseModal(false);
          }
        }}
      />
    </div>
  );
};

export default LocationInput;
