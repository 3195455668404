import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  MdStar,
  MdOutlineWorkOutline,
  MdOutlineLocationOn,
  MdPermPhoneMsg,
} from "react-icons/md";
import { FaMinus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import JobService from "../../../services/JobService";
import { NotificationManager } from "react-notifications";
import { getActiveJob, getHeroActiveBid } from "../../../redux/actions/app";
import { isEmpty } from "../../../utils/common";
import ClothModal from "../../../components/ClothModal";
import ClothItem from "../../../components/ClothItem";
import Avatar from "../../../components/Avatar";
import { Spin } from "antd";
import "./index.css";
import WhatsappBtn from "../../../components/WhatsappBtn";

const Panel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { job_id } = useParams();
  const { state } = useLocation();
  const { activeJob } = useSelector((state) => state.app);
  const [showConfirmComplete, setShowConfirmComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const startY = useRef(0);
  const isPullingDown = useRef(false);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    startY.current = touch.clientY;
  };

  const handleTouchMove = (e) => {
    if (isPullingDown.current) return; // Prevent multiple events
    const touch = e.touches[0];
    const distanceY = touch.clientY - startY.current;

    // Check if the distance is enough to consider it a pull down
    if (distanceY > 50) {
        isPullingDown.current = true;
        onToggle();
    }
    else if (distanceY < -50)
    {
        isPullingDown.current = true;
        onToggle();
    }
  };

  const handleTouchEnd = () => {
    isPullingDown.current = false;
  };

  const onImHere = async () => {
    setLoading(true);
    try {
      await JobService.updateHeroArrived({
        job_id: activeJob?.id,
      });
      await dispatch(getActiveJob());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      NotificationManager.error(
        error?.message ||
          "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
        "Error",
        3000
      );
    }
  };

  const onJobCompeted = async () => {
    try {
      setLoading(true);
      await JobService.complete({ job_id: job_id });
      await dispatch(getActiveJob());
      setLoading(false);
      navigate("/hero/jobs");
    } catch (error) {
      setLoading(false);
      NotificationManager.error(
        error?.message ||
          "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
        "Error",
        3000
      );
    }
  };

  const onToggle = () => {
    const panel = document.getElementById(
      showConfirmComplete ? "hero-job-home-panel-ext" : "hero-job-home-panel"
    );
    panel.classList.toggle("close"); // Toggle the 'open' class
  };

  return (
    <div
      className="w-full rounded-tl-[14px] rounded-tr-[14px] bg-primary1 drop-shadow"
      id={
        showConfirmComplete ? "hero-job-home-panel-ext" : "hero-job-home-panel"
      }
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className=" cursor-pointer w-full h-[28px] pt-2 flex justify-center items-start rounded-tl-[14px] rounded-tr-[14px] bg-primary1"
        onClick={onToggle}
      >
        <div className="w-[28px] h-[4px] rounded-full bg-[#666]" />
      </div>
      {showConfirmComplete && (
        <div className="w-full p-2 flex justify-center items-center bg-primary1">
          <p className="text-[15px] text-white font-bold">
            Is the job completed?
          </p>
        </div>
      )}
      <div className=" p-4 w-full bg-[#323943]">
        <div className="w-full flex justify-start items-center relative pt-8 pb-4">
          <div className="mr-4 flex flex-col justify-center items-center">
            <Avatar image={activeJob?.customer?.photo} />
            <p className="mt-1 text-[12px] text-white font-bold">
              {activeJob?.customer?.name}
            </p>
            <div className="flex justify-center items-center">
              <MdStar color="#ffff00" size={13} />
              <span className="text-[11px] text-white font-semibold">
                {parseFloat(activeJob?.customer?.rating).toFixed(1)} (
                {activeJob?.customer?.rating_cnt})
              </span>
            </div>
          </div>
          <div className="flex-1 flex justify-start items-start">
            {!isEmpty(activeJob?.customer_wear_top) &&
              !isEmpty(activeJob?.customer_wear_bottom) && (
                <ClothItem
                  topName={activeJob?.customer_wear_top}
                  bottomName={activeJob?.customer_wear_bottom}
                  type={"both"}
                />
              )}
          </div>
          <WhatsappBtn user={activeJob?.customer} />
        </div>
        <div className="w-full mt-2 flex justify-start items-center">
          <MdOutlineWorkOutline color="#fff" size={16} />
          <p className="ml-2 w-full text-[16px] leading-[24px] text-white font-bold">
            {activeJob?.title}
          </p>
        </div>
        <div className="w-full mt-2 flex justify-start items-center">
          <MdOutlineLocationOn color="#fff" size={16} />
          <p className="ml-2 w-full text-[16px] leading-[21px] text-white ">
            {activeJob?.formatted_address}
          </p>
        </div>
        <p className="mt-2 w-full text-[16px] leading-[21px] text-[#999] ">
          {" "}
          RM {parseInt(activeJob?.price)}
        </p>
      </div>
      <div className="w-full p-3 bg-primary1">
        {!activeJob?.hero_arrived ? (
          <button
            className="w-full h-[40px] rounded-[10px] bg-primary2 text-white text-[15px]"
            onClick={onImHere}
          >
            I'm here
          </button>
        ) : !showConfirmComplete ? (
          <button
            className="w-full h-[40px] rounded-[10px] bg-primary2 text-white text-[15px]"
            onClick={() => setShowConfirmComplete(true)}
          >
            Job Completed
          </button>
        ) : (
          <div className="w-full">
            {loading ? (
              <div className=" w-full flex justify-center items-center h-[40px]">
                <Spin />
              </div>
            ) : (
              <button
                className="w-full h-[40px] rounded-[10px] bg-primary2 text-white text-[15px]"
                onClick={onJobCompeted}
              >
                Yes
              </button>
            )}
            <button
              className="mt-3 w-full h-[40px] rounded-[10px] bg-[#323943] text-white text-[15px]"
              onClick={() => setShowConfirmComplete(false)}
            >
              No
            </button>
          </div>
        )}
      </div>
      {activeJob != null &&
        (isEmpty(activeJob?.hero_wear_top) ||
          isEmpty(activeJob?.hero_wear_bottom)) && (
          <ClothModal
            showModal={
              activeJob != null &&
              (isEmpty(activeJob?.hero_wear_top) ||
                isEmpty(activeJob?.hero_wear_bottom))
            }
            isCustomer={false}
            job={activeJob}
          />
        )}
    </div>
  );
};

export default Panel;
