import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import { changeLanguage, setDarkMode } from "../../redux/actions/app";
import AreYouSureModal from "../../components/AreYouSureModal";
import { useState } from "react";
import useTrans from "../../components/Hooks/useTrans";
import { Langs } from "../../constants/config";

const Languages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trans = useTrans();

  return (
    <div className="w-full h-full   bg-white-primary1 flex flex-col justify-center items-center">
      <div className="w-full flex justify-start items-center  py-4  bg-white-primary1 drop-shadow">
        <div className="w-[50px] flex justify-center items-center">
          <FiArrowLeft
            size={20}
            className="text-primary1-white"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className=" flex-1 text-center text-[16px] text-primary1-white font-bold ml-3">
          {trans("Language")}
        </div>
        <div className="w-[50px] flex justify-center items-center"></div>
      </div>
      <div className="mt-6 pb-5 flex-1 overflow-y-auto w-full flex flex-col px-5 justify-start items-center gap-5">
        {Langs.map((lang) => (
          <div key={lang.id} className="w-full flex justify-center items-center"
            onClick={()=> dispatch(changeLanguage(lang.id))}
          >
            <div className="flex-1">
              <p className="text-[15px] text-primary1-white font-semibold">
                {lang.title}
              </p>
              <p className="mt-1 text-[12px] text-black2-gray3">
                {lang.name}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Languages;
