import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import { getCurrentWeekRange, getDateRange } from "../../utils/date";
import { Spin } from "antd";
import StatisticsService from "../../services/StatisticsService";

const Income = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("Day");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dateRange, setDateRange] = useState(null);
  const [incomeData, setIncomeData] = useState({
    total: 44,
    fee: 4.4,
    net_income: 39.6,
    total_orders: 5,
    total_hours: 4,
    per_hour: 22,
  });

  useEffect(() => {
    setCurrentDate(new Date());
  }, [tab]);

  useEffect(() => {
    const dateData = getDateRange(tab, currentDate);
    loadData(dateData);
  }, [currentDate]);

  const loadData = (dateData) => {
    console.log('date data ', dateData);
    setLoading(true);
    StatisticsService.getIncomeData({from : dateData.from, to: dateData.to})
      .then((res) => {
        setDateRange(dateData);
        const hours = parseFloat((res?.data?.total_seconds ?? 0) / 3600).toFixed(2);
        setIncomeData({
          total: parseFloat(res?.data?.total ?? 0).toFixed(1),
          fee: parseFloat((res?.data?.total ?? 0) * 0.1).toFixed(1),
          net_income: parseFloat((res?.data?.total ?? 0) * 0.9).toFixed(1),
          total_orders: res?.data?.total_jobs,
          total_hours: hours,
          per_hour: (hours == 0 ? 0 : parseFloat((res?.data?.total ?? 0) / hours).toFixed(1)),
        });
        console.log('getIncomeData ', res)
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        NotificationManager.error(
          e?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
          "Error",
          3000
        );
      });
  };

  function getPreviousRange() {
    const newDate = new Date(currentDate);

    if (tab === "Day") {
      newDate.setDate(newDate.getDate() - 1);
    } else if (tab === "Week") {
      newDate.setDate(newDate.getDate() - 7);
    } else if (tab === "Month") {
      newDate.setMonth(newDate.getMonth() - 1);
    }

    setCurrentDate(newDate);
  }

  function getNextRange() {
    const newDate = new Date(currentDate);

    if (tab === "Day") {
      newDate.setDate(newDate.getDate() + 1);
    } else if (tab === "Week") {
      newDate.setDate(newDate.getDate() + 7);
    } else if (tab === "Month") {
      newDate.setMonth(newDate.getMonth() + 1);
    }

    setCurrentDate(newDate);
  }

  function onHistory() {
    navigate('/hero/job-history', {state: {dateRange: dateRange}})
  }

  return (
    <div className="w-full flex-1 overflow-y-auto flex flex-col bg-white-primary1 ">
      <div className="w-full px-[30px] mt-6 flex justify-center items-center">
        {["Day", "Week", "Month"].map((item) => (
          <button
            key={item}
            className={`py-2 px-4 ${
              item == tab
                ? "bg-primary2-white text-white-primary1"
                : "bg-gray5-primary2 text-white"
            }`}
            onClick={() => setTab(item)}
          >
            {item}
          </button>
        ))}
      </div>
      {dateRange == null ? (
        <div className="w-full flex justify-center items-center p-10">
          <Spin />
        </div>
      ) : (
        <>
          <div className="w-full flex items-center mt-6">
            <div className="p-6 cursor-pointer" onClick={getPreviousRange}>
              <FiChevronLeft size={24} className={"text-primary1-white"} />
            </div>
            <div className="px-4 flex-1 flex flex-col justify-center items-center">
              <p className="text-[16px] font-semibold text-primary1-white">
                {dateRange.date_str}
              </p>
              <h1 className="text-[32px] font-bold text-primary1-white mt-2">
                RM{incomeData?.net_income}
              </h1>
              <p className="text-[14px] text-black3-gray1">Net income</p>
            </div>
            <div className={`p-6 cursor-pointer ${moment(moment(currentDate).format('YYYY-MM-DD')).isSameOrAfter(moment(moment().format('YYYY-MM-DD'))) ? 'opacity-50': ''}`} onClick={() => {
              if (moment(moment(currentDate).format('YYYY-MM-DD')).isBefore(moment(moment().format('YYYY-MM-DD')))) {
                getNextRange()
              }
            }}>
              <FiChevronRight size={24} className={"text-primary1-white"} />
            </div>
          </div>
          <div className="w-full mt-6 px-5 pb-2 flex justify-between items-center border-b-4 border-b-black1">
            <div className="flex flex-col justify-center items-center">
              <p className="text-[16px] text-primary1-white">
                {incomeData?.total_orders}
              </p>
              <p className="mt-1 text-[15px] text-black3-gray1">Orders</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <p className="text-[16px] text-primary1-white">
                {incomeData?.total_hours}
              </p>
              <p className="mt-1 text-[15px] text-black3-gray1">hours</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <p className="text-[16px] text-primary1-white">
                RM{incomeData?.per_hour}
              </p>
              <p className="mt-1 text-[15px] text-black3-gray1">Per hour</p>
            </div>
          </div>
          <div className="w-full px-5 py-6">
            <p className="text-[15px]  text-black3-gray1">Received</p>
            <div className="mt-5 w-full flex justify-between items-center">
              <p className="text-[15px] font-medium text-primary1-white">
                Total Income
              </p>
              <p className="text-[15px] font-semibold text-primary1-white">
                RM{incomeData?.total}
              </p>
            </div>
            <p className="mt-6 text-[15px]  text-black3-gray1">Paid</p>
            <div className="mt-5 w-full flex justify-between items-center">
              <p className="text-[15px] font-medium text-primary1-white">
                Service payments
              </p>
              <p className="text-[15px] font-semibold text-primary1-white">
                RM{incomeData?.fee}
              </p>
            </div>
          </div>
          <div
            className="px-5 my-6 w-full flex items-center"
            onClick={onHistory}
          >
            <RiCheckboxMultipleLine
              size={20}
              className={"text-primary1-white"}
            />
            <span className="pl-1 text-[14px] text-primary1-white">
              Go to job history
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Income;
