import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import "./assets/index.css";
import "./assets/anim-bg.css";
import App from "./containers";
import {Provider} from "react-redux";
import store from "./redux/store";
// import "./localisations/index"; 
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

serviceWorkerRegistration.register();