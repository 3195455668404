import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { MdStar, MdOutlineWorkOutline, MdOutlineLocationOn, MdPermPhoneMsg } from "react-icons/md";
import { BsCash } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { currencies, GOOGLE_MAP_API_KEY, TaskExamples } from '../../constants/config';
import { useDispatch, useSelector } from 'react-redux';
import LocationMarker from '../../components/Markers/LocationMarker';
import JobService from '../../services/JobService';
import { NotificationManager } from "react-notifications";
import { getHeroActiveBid } from '../../redux/actions/app';
import CancelJobModal from '../../components/CancelJobModal';
import { isEmpty } from '../../utils/common';
import ClothModal from '../../components/ClothModal';
import ClothItem from '../../components/ClothItem';
import Avatar from '../../components/Avatar';
import HeroArrivedNoti from '../../components/HeroArrivedNoti';
import GoogleMap from '../../components/Maps/GoogleMap';


const CustomerJobHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { job_id } = useParams();
    const { state } = useLocation();
    const { activeJob } = useSelector(state => state.app);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCancelJob = (reason) => {
        setLoading(true);
        JobService.cancelJob({
            job_id: job_id,
            reason: reason
        }).then((res) => {
            console.log('cancel job ', res)
            setLoading(false);
            navigate('/customer');
        }).catch(e => {
            console.log(e)
            setLoading(false);
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        });
    }


    return (
        <div className='w-full h-full  flex flex-col justify-center items-center'>
            <div className='w-full flex-1 relative'>
                <GoogleMap
                    center={activeJob}
                    user={activeJob?.hero}
                />
            </div>
            <div className='w-full rounded-tl-[14px] rounded-tr-[14px] bg-[#1c1f24] drop-shadow'>
                <div className='w-full rounded-tl-[14px] rounded-tr-[14px]'>
                    <div className='w-full p-4'>
                        {
                            (activeJob?.hero_arrived && activeJob?.customer_coming != 1) ?
                                <p className='text-[16px] text-white font-bold'>
                                    {activeJob?.hero?.name} is waiting for you
                                </p>
                                :
                                (activeJob?.customer_coming ?
                                    <p className='text-[16px] text-white font-bold'>
                                        Go meet up Hero {activeJob?.hero?.name} <br />
                                        Working on the job
                                    </p>
                                    :
                                    (activeJob?.hero_arrived != 1 ?
                                        <p className='text-[16px] text-white font-bold'>
                                            Hero {activeJob?.hero?.name} <br />
                                            will arrive in ~1 min.
                                        </p>
                                        : <div />
                                    )
                                )
                        }
                        <HeroArrivedNoti />
                    </div>
                    <div className="w-full p-4 flex justify-start items-center border-t border-t-[#666] border-b border-b-[#666]"  >
                        <div className="mr-4 pr-2 relative">
                            <div className="mr-4 flex flex-col justify-center items-center relative">
                                <Avatar image={activeJob?.hero?.photo} />
                                <p className="mt-1 text-[12px] text-white font-bold">{activeJob?.hero?.name}</p>
                            </div>
                            <div className="flex justify-center items-center drop-shadow px-1 bg-white rounded-full absolute top-0 right-0">
                                <MdStar color="#ff9900" size={13} />
                                <span className="text-[11px] text-[#000] font-semibold whitespace-nowrap ">{parseFloat(activeJob?.hero?.rating).toFixed(1)}</span>
                            </div>
                        </div>
                        <div className='flex-1 flex justify-start items-start'>
                            {
                                (!isEmpty(activeJob?.hero_wear_top) && !isEmpty(activeJob?.hero_wear_bottom)) &&
                                <ClothItem
                                    topName={activeJob?.hero_wear_top}
                                    bottomName={activeJob?.hero_wear_bottom}
                                    type={'both'}
                                />
                            }
                        </div>
                        <div className='w-[40px] h-[40px] rounded-full bg-primary2 flex justify-center items-center'>
                            <MdPermPhoneMsg color={'#1c1f24'} />
                        </div>
                    </div>
                    <div className='p-4 w-full'>
                        <p className='text-[14px] text-[#aaa]'>Payment</p>
                        <div className="w-full mt-2 flex justify-start items-center">
                            <BsCash color="#fff" size={16} />
                            <p className="ml-2 w-full text-[16px] leading-[21px] text-white "> RM {parseInt(activeJob?.price)}</p>
                        </div>
                        <p className='mt-4 text-[14px] text-[#aaa]'>Your current job</p>
                        <div className="w-full mt-2 flex justify-start items-center">
                            <MdOutlineWorkOutline color="#fff" size={16} />
                            <p className="ml-2 w-full text-[16px] leading-[24px] text-white font-bold">{activeJob?.title}</p>
                        </div>
                        <div className="w-full mt-2 flex justify-start items-center">
                            <MdOutlineLocationOn color="#fff" size={16} />
                            <p className="ml-2 w-full text-[16px] leading-[21px] text-white ">{activeJob?.formatted_address}</p>
                        </div>
                    </div>
                </div>
                <div className='w-full p-3 '>
                    <button className='w-full h-[40px] rounded-[10px] bg-primary2 text-white text-[15px]'
                        onClick={() => setShowCancelModal(true)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            {
                showCancelModal &&
                <CancelJobModal
                    showModal={showCancelModal}
                    onClose={(data) => {
                        setShowCancelModal(false);
                        if (!isEmpty(data)) {
                            handleCancelJob(data);
                        }
                    }}
                />
            }
            {
                (activeJob != null && (isEmpty(activeJob?.customer_wear_top) || isEmpty(activeJob?.customer_wear_bottom))) &&
                <ClothModal
                    showModal={(activeJob != null && (isEmpty(activeJob?.customer_wear_top) || isEmpty(activeJob?.customer_wear_bottom)))}
                    isCustomer={true}
                    job={activeJob}
                />
            }
        </div>
    )
}

export default CustomerJobHome;
