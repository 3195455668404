import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateUserData } from "../../redux/actions/auth";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import ImgPerson from "../../assets/images/placeholder.jpg";
import { isEmpty } from "../../utils/common";
import {
  FaAddressBook,
  FaHome,
  FaPhone,
  FaPhoneAlt,
  FaUser,
} from "react-icons/fa";
import { MdApartment } from "react-icons/md";
import { Spin } from "antd";
import LocationInput from '../../components/LocationInput';

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const file_input = useRef(null);
  const [loading, setLoading] = useState(false);
  const [photoData, setPhotoData] = useState(null);
  const [name, setName] = useState(user.name);
  const [curLoc, setCurLoc] = useState({});
  const [address_changed, setAddressChanged] = useState(false);

  const onHandleGalleryImg = ({ target }) => {
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPhotoData(reader.result);
      }
    };
  };

  const _changeLocPlace = (place) => {
    setAddressChanged(true);
    setCurLoc(place);
  };

  const onSave = () => {
    setLoading(true);
    dispatch(
      updateUserData({
        name: name,
        photo: photoData == null ? null : photoData.split(",")[1],
        address_changed: address_changed,
        ...curLoc
      })
    )
      .then((data) => {
        NotificationManager.success(
          data?.message || "Profile has been updated successfully",
          "Success",
          5000
        );
        setLoading(false);
        setAddressChanged(false);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
          "Error",
          3000
        );
        setLoading(false);
      });
  };

  return (
    <div className="w-full h-full  bg-white-primary1 flex flex-col justify-center items-center">
      <div className="w-full flex justify-start items-center  py-4 bg-white-primary1 drop-shadow">
        <div className="w-[50px] flex justify-center items-center">
          <FiArrowLeft
            size={20}
            className="text-primary1-white"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className=" flex-1 text-center text-[16px] text-primary1-white font-bold ml-3">
          Profile settings
        </div>
        <div className="w-[50px] flex justify-center items-center"></div>
      </div>
      <div className="mt-6 flex-1 w-full flex flex-col px-5 justify-start items-center">
        <div className="w-full flex justify-center items-center">
          <img
            className={`w-[160px] h-[160px] rounded-full object-cover`}
            src={
              photoData
                ? photoData
                : isEmpty(user.photo)
                ? ImgPerson
                : user.photo
            }
            onClick={() => {
              if (file_input.current != null) {
                file_input.current.click();
              }
            }}
          />
          <input
            accept="image/jpeg/png"
            ref={file_input}
            hidden={true}
            type="file"
            onChange={onHandleGalleryImg}
          />
        </div>
        <div className="mt-4 py-[8px] w-full flex justify-center items-center border-b border-b-[#ccc]">
          <FaUser color={"#ccc"} size={20} />
          <input
            className="flex-1 ml-3 outline-none text-primary1-white bg-white-primary1"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mt-2 w-full py-[8px] flex justify-center items-center border-b border-b-[#ccc]">
          <FaPhoneAlt color={"#ccc"} size={20} />
          <p className="flex-1 ml-3 text-[15px] text-primary1-white">
            {user.phone}
          </p>
        </div>
        <LocationInput
          placeholder={"Your location"}
          icon={<MdApartment color={"#ccc"} size={24} />}
          defaultInput={user?.address?.formatted_address}
          onChangePlace={_changeLocPlace}
          className="mt-2 w-full py-[8px] flex justify-center items-center border-b border-b-[#ccc] !p-0 !bg-transparent"
          inputClassName={'!text-white'}
        />
        {loading ? (
          <div className="mt-8 w-full h-[40px] flex justify-center items-center">
            <Spin />
          </div>
        ) : (
          <button
            className="mt-8 bg-primary2 text-white font-semibold text-[15px] rounded-[5px] w-full h-[40px]"
            onClick={onSave}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default Profile;
