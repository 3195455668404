import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

export default function useTrans() {
    const { translates, language } = useSelector(state => state.app)
 
    const trans = useCallback((key) => {
        if (translates[key]) {
            return translates[key][language] ?? key;
        }
        return key;
    }, [translates, language]) 

    return trans;
}