import apiFactory from "../utils/apiFactory";
import {
  API_STATISTICS
} from "../constants/apiConfig";

export default class StatisticsService {
  static getIncomeData = async (payload) => {
    let api = {
      ...API_STATISTICS,
      path: `${API_STATISTICS.path}/income`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static getJobHistory = async (payload) => {
    let api = {
      ...API_STATISTICS,
      path: `${API_STATISTICS.path}/job-history`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
}
