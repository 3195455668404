import moment from "moment";

export function getDateRange(duration = 'Day', currentDate) {
    let from, to, dateStr;
    if (duration == 'Day') {
        from = to = currentDate;
        dateStr = moment(currentDate).format('DD MMM YYYY');
    }
    else if (duration == 'Week') 
    {
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // start of the week (Sunday)
        
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // end of the week (Saturday)
        
        from = startOfWeek;
        to = endOfWeek;
        
        // Format the week range
        let startStr = from.toLocaleDateString("en-GB", { day: 'numeric', month: 'short' });
        let endStr = to.toLocaleDateString("en-GB", { day: 'numeric', month: 'short', year: 'numeric' });
        
        if (endStr.includes(startStr.slice(-3)))
        {
            startStr = from.toLocaleDateString("en-GB", { day: 'numeric'});
            endStr = to.toLocaleDateString("en-GB", { day: 'numeric', month: 'short', year: 'numeric' });
            dateStr = `${startStr} - ${endStr}`;
        }
        else {
            dateStr = `${startStr} - ${endStr}`;
        }
    }
    else if (duration == 'Month')
    {
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        
        from = startOfMonth;
        to = endOfMonth;
        
        dateStr = from.toLocaleDateString("en-GB", { month: 'long', year: 'numeric' });
    }
    
    return { date_str: dateStr, from: moment(from).format('YYYY-MM-DD 00:00:00'), to: moment(to).format('YYYY-MM-DD 23:59:59') };
}