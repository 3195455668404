import apiFactory from "../utils/apiFactory";
import {
  API_JOB
} from "../constants/apiConfig";

export default class JobService {
  static createJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/create`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static getAll = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}`,
      method: "GET",
    };

    return apiFactory(api, payload);
  };

  static reportSpamJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/report-spam`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static hideJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/hide`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static cancelJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/cancel`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static placeBid = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/create-bid`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static getAllBids = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/bids`,
      method: "GET",
    };

    return apiFactory(api, payload);
  };

  static getHeroActiveBid = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/active-bid`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };

  static getCustomerActiveJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/active`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };

  static getHeroActiveJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/hero-activejob`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };

  static acceptDeclineBid = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/update-bid`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static updateCustomerWear = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/customer-wear`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static updateHeroWear = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/hero-wear`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static updateHeroArrived = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/hero-arrived`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static updateCustomerComing = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/customer-coming`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static complete = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/complete`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static getHeroUnRatedJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/hero-unrated-job`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };

  static getCustomerUnRatedJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/customer-unrated-job`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };
 
  static getCancelJobNotify = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/cancel-notify`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };

  static getAcceptJobNotify = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/accept-notify`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };  

  static leaveReview = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/review`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static updateCustomerPrice = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/customer-update-price`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static makeHeroSeenJob = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/hero-seen`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static getSeenHeroList = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/get-seen-heros`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };
  
  static getNewJobsCnt = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/new-cnt`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };

  static updateJobLoaded = async (payload) => {
    let api = {
      ...API_JOB,
      path: `${API_JOB.path}/update-job-loaded`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };
  
}
