import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "../../utils/common";
import ImgMap from "../../assets/images/gmap.png";
import { useDispatch } from "react-redux";
import { updateUserAddress } from "../../redux/actions/auth";
import { KUALALUMPUR_ADDRESS } from "../../constants/config";
import { NotificationManager } from "react-notifications";
import { Spin } from "antd";
import ChooseLocation from "../../components/ChooseLocation";

const InitLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showChooseModal, setShowChooseModal] = useState(false);

  const onSubmit = (address) => {
    setLoading(true);
    dispatch(updateUserAddress(address))
      .then((res) => {
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        NotificationManager.error(
          e?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
          "Error",
          3000
        );
      });
  };

  const onOtherLocation = () => {
    setShowChooseModal(true);
  };

  return (
    <div className="w-full h-full py-5 bg-primary1 flex flex-col justify-center items-center">
      <div className="w-full flex-1 flex flex-col justify-center items-center pt-5 pb-10">
        <img src={ImgMap} className="w-[150px] object-contain" />
        <h1 className="mt-10 text-[18px] text-white font-bold">
          Are you in Kuala Lumpur?
        </h1>
      </div>
      <div className="w-full px-5">
        {loading ? (
          <div className="mt-9 w-full flex justify-center items-center h-[42px]">
            <Spin />
          </div>
        ) : (
          <button
            className="mt-9 w-full h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold"
            onClick={() => onSubmit(KUALALUMPUR_ADDRESS)}
          >
            Yes, I'm here
          </button>
        )}
        <button
          className="mt-3 w-full h-[42px] rounded-[4px] bg-[#999] text-white text-[14px] font-semibold"
          onClick={onOtherLocation}
        >
          No
        </button>
      </div>
      <ChooseLocation
        showModal={showChooseModal}
        onClose={(data) => {
          if (data != null) {
            onSubmit(data);
          } else {
            setShowChooseModal(false);
          }
        }}
      />
    </div>
  );
};

export default InitLocation;
