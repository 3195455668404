import { SET_LANGUAGE, SET_SHOW_TOP_BTN, APP_SET_ACTIVE_BID, APP_SET_ACTIVE_JOB, APP_SET_ENLARGED_IMAGE, SET_SHOW_SIDEBAR, SET_DARK_MODE, APP_SET_NEW_JOBS_CNT, SET_TRANSLATES } from '../actions/types';

const initialState = {
    translates: [],
    language: 'en',
    showTopBtn: false,
    showSidebar: false,
    isDarkMode: false,
    newJobsCnt: 0,
    newJobIds: [],
    activeBid: null,
    activeJob: null,
    enlargedImg: null
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSLATES:
            return { ...state, translates: action.payload || [] };
        case SET_LANGUAGE:
            return { ...state, language: action.payload || 'en' };
        case SET_SHOW_TOP_BTN:
            return { ...state, showTopBtn: action.payload || false };
        case SET_SHOW_SIDEBAR:
            return { ...state, showSidebar: action.payload || false };
        case SET_DARK_MODE:
            return { ...state, isDarkMode: action.payload || false };
        case APP_SET_ACTIVE_BID:
            return { ...state, activeBid: action.payload };
        case APP_SET_ACTIVE_JOB:
            return { ...state, activeJob: action.payload };
        case APP_SET_ENLARGED_IMAGE:
            return { ...state, enlargedImg: action.payload };
        case APP_SET_NEW_JOBS_CNT:
            return { ...state, newJobsCnt: action.cnt || 0, newJobIds: action.new_job_ids || []};
        default:
            return state;
    }
};

export default app;
