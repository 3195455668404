import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { MdStar } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Avatar from "../Avatar";
import ProgressTimer from "../ProgressTimer";
import './index.css';

const WaitingCustomerModal = ({ bid, showModal }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(showModal);
  }, [showModal])

  return (
    <Modal
      open={open}
      closable={false}
      maskClosable={false}
      footer={null}
      closeIcon={null}
      centered
      width={'100vw'}
      className="active-bid-modal w-full max-w-md bg-[#00000099]"
    >
      <div className="flex flex-col h-full w-full justify-center items-center ">
        <div className="flex-1 flex justify-center items-center">
          <p className="text-[20px] leading-[36px] text-white text-center">Offering your Price<br />RM {parseInt(bid?.price)}<br />Waiting for the Reply</p>
        </div>
        <div className="w-full flex p-6 justify-center items-start rounded-tl-[10px] rounded-tr-[10px] bg-[#00000099]"  >
          <div className="mr-4 flex flex-col justify-center items-center">
            <Avatar image={bid?.job?.customer?.photo} className="w-[60px] h-[60px]"/>
            <p className="mt-1 text-[14px] text-white font-bold">{bid?.job?.customer?.name}</p>
            <div className="flex justify-center items-center">
              <MdStar color="#ffff00" size={13} />
              <span className="text-[13px] text-white font-semibold">{parseFloat(bid?.job?.customer?.rating).toFixed(1)} ({bid?.job?.customer?.rating_cnt})</span>
            </div>
          </div>
          <div className="h-full flex flex-1 flex-col justify-start items-start pb-3">
            <p className="w-full text-[19px] leading-[24px] text-white font-bold">{bid?.job?.title}</p>
            <p className="mt-2 w-full text-[16px] leading-[21px] text-[#999] ">{bid?.job?.formatted_address}</p>
            <p className="mt-2 w-full text-[18px] leading-[24px] text-[#f00] font-bold">RM {parseInt(bid?.job?.price)}</p>
          </div>
        </div>
        <ProgressTimer duration={30}/> 
      </div>
    </Modal>
  );
};

export default WaitingCustomerModal;


