import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import "./index.css";
import { MdClose } from "react-icons/md";
import BeepSound from "../../assets/audios/canceled.mp3";

const CancelJobNotifyModal = ({
  isCustomer = false,
  showModal,
  job,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [audio] = useState(new Audio(BeepSound));

  useEffect(() => {
    setOpen(showModal);
    if (showModal) {
      playBeep();
    } else {
      stopBeep();
    }
    return () => {
      stopBeep();
    }
  }, [showModal]);

  const playBeep = () => {
    try {
      if (audio) {
        audio.loop = true;
        audio.play().catch((error) => {
          console.error("Playback failed: ", error);
        });
      }
    } catch (error) {
      console.log("play beep error ", error);
    }
  };

  const stopBeep = () => {
    console.log('stop beep')
    try {
      if (audio) {
        audio.pause().catch((error) => {
          console.error("Playback failed: ", error);
        });
      }
    } catch (error) {
      console.log("play beep error ", error);
    }
  };

  return (
    <Drawer
      placement={"bottom"}
      open={open}
      closable={false}
      rootClassName="w-full max-w-md mx-auto"
      className="cancel-notify-modal"
      height={180}
      onClose={onClose}
    >
      <div className="bg-[#1c1f24] px-5 py-10">
        <p className="w-full text-center text-white text-[20px] font-bold">
          {isCustomer ? "Hero" : "Customer"} has cancelled <br /> the job.
        </p>
        <div
          className="absolute top-3 right-4 cursor-pointer w-8 h-8 rounded-full flex items-center justify-center bg-black2"
          onClick={onClose}
        >
          <MdClose className="  text-white " size={22} />
        </div>
      </div>
    </Drawer>
  );
};

export default CancelJobNotifyModal;
