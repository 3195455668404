// Auth
export const API_Auth = {
  content_type: "application/json",
  path: "auth",
  has_token: false,
};

// User
export const API_User = {
  content_type: "application/json",
  path: "user",
  has_token: true,
};

// Jobs
export const API_JOB = {
  content_type: "application/json",
  path: "jobs",
  has_token: true,
};

// Statistics
export const API_STATISTICS = {
  content_type: "application/json",
  path: "statistics",
  has_token: true,
};