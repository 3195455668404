import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import UserService from "../../services/UserService";
import Avatar from "../../components/Avatar";
import { Rate } from "antd";
import moment from "moment";
import StatisticsService from "../../services/StatisticsService";
import { NotificationManager } from "react-notifications";
import { JOB_STATUS } from "../../constants/config";
import { MdOutlineLocationOn, MdOutlineWorkOutline } from "react-icons/md";

const JobHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData(state.dateRange);
  }, [state.dateRange]);

  const loadData = (dateData) => {
    console.log("date data ", dateData);
    setLoading(true);
    StatisticsService.getJobHistory({ from: dateData.from, to: dateData.to })
      .then((res) => {
        console.log("getJobHistory ", res);
        let historyData = [];
        let history = res.data || [];
        for (let i = 0; i < history.length; i++) {
          const date = moment(history[i].updated_at).format("DD MMM");
          if (historyData[date] == null) {
            historyData[date] = {
              total: 0,
              data: [],
            };
          }
          historyData[date].total =
            historyData[date].total +
            (history[i].status == JOB_STATUS.CANCELLED
              ? 0
              : parseFloat(history[i].final_price));
          historyData[date].data.push(history[i]);
        }
        setData(historyData);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        NotificationManager.error(
          e?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
          "Error",
          3000
        );
      });
  };

  return (
    <div className="w-full h-full  bg-white-primary1 flex flex-col justify-center items-center">
      <div className="w-full flex justify-start items-center  py-4 bg-white-primary1 drop-shadow">
        <div className="w-[50px] flex justify-center items-center">
          <FiArrowLeft
            size={20}
            className="text-primary1-white"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className=" flex-1 text-center text-[16px] text-primary1-white font-bold ml-3">
          Job History
        </div>
        <div className="w-[50px] flex justify-center items-center"></div>
      </div>
      <div className="my-5 flex-1 w-full flex flex-col justify-start items-center overflow-y-auto">
        {Object.keys(data).map((date) => (
          <div className="w-full" key={date}>
            <div className="w-full my-3 text-[17px]  px-5 leading-[26px] text-primary1-white font-bold">
              {date} - RM{data[date].total}
            </div>
            {data[date].data.map((job) => (
              <div key={job.id} className="w-full py-3 px-5 border-b border-b-gray1">
                <div className="w-full flex justify-between items-center">
                  <p className="  text-[15px] leading-[21px] text-primary1-white font-bold">
                    {moment(job.updated_at).format("h:mm a")}
                  </p>
                  <p className=" text-[15px] leading-[21px] text-primary1-white font-bold">
                    {job.status == JOB_STATUS.CANCELLED
                      ? "RM0.00"
                      : "RM" + parseFloat(job.final_price).toFixed(2)}
                  </p>
                </div>
                <div className="w-full mt-3 flex justify-start items-center opacity-70">
                  <MdOutlineWorkOutline color="#fff" size={16} />
                  <p className="ml-2 w-full text-[15px] leading-[21px] text-primary1-white">
                    {job?.title}
                  </p>
                </div>
                <div className="w-full mt-3 flex justify-start items-center opacity-70">
                  <MdOutlineLocationOn color="#fff" size={16} />
                  <p className="ml-2 w-full text-[15px] leading-[21px] text-primary1-white ">
                    {job?.formatted_address}
                  </p>
                </div>
                {job.status == JOB_STATUS.CANCELLED && (
                  <div className="mt-3 py-1 px-3 rounded bg-black2 text-primary1-white text-14">
                    Cancelled
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobHistory;
